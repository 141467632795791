

body, p, a, ul, ol, li {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

span {
    font-family: 'Lato', sans-serif;
}

body {
    overflow-x: hidden;
}

.main-brand-logo {
    max-width: 100%;
}

.brand-logo{
    width: 250px;
}

#banner-section p, #counter-section p, #nochebuena-section p, #donation-section p, #xmascard-section p {
    font-size: 26px;
    line-height: 1.3;
}

#counter-section h2, #nochebuena-section h2, #donation-section h2 {
    font-size: 38px;
}

.orange-btn {
    background-color: #FF6A00;
    box-shadow: 0px 6px 9px #00000052;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    /* padding: 18px 25px; */
    /* font-size: 19px; */
    display: inline-block;
    border-radius: 30px;
    padding: 10px 30px !important;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center !important;
}

.orange-btn:hover {
    background-color: #ff8936;
    color: #fff;
}


.blue-btn {
    background-color: #00acca;
    box-shadow: 0px 6px 9px #00000052;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    /* padding: 18px 25px; */
    /* font-size: 19px; */
    display: inline-block;
    border-radius: 30px;
    padding: 10px 30px !important;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center !important;
}

.blue-btn:hover {
    background-color: #00bfe0;
    color: #fff;
}

#top-header {
    padding: 20px 0;
}

#top-header .top-menu {
    list-style-type: none;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 0;
    padding-left: 0;
}

#top-header .top-menu li {
    display: inline;
}

#top-header .top-menu li a {
    color: #000;
    font-size: 1.2rem;
    letter-spacing: -0.5px;
    text-decoration: none;
    padding: 10px 17px;
}

#top-header .mobile-menu, #side-menu {
    display: none;
}

#top-header .header-cta-btn {
    text-align: right;
}

#banner-section {
    /* background: #F9F8F7 url(../img/levelup-banner.jpg) no-repeat right 0% bottom 45%; */
    background-size: cover;
}

#banner-section .container{
    height: 500px;
}

#banner-section h1, #banner-section p {
    color: #fff;
}

#banner-section h1 {
    font-size: 6.5rem !important; 
    font-weight: bold;
    font-style: italic;
    margin-bottom: 5px;
    margin-top: 50px;
}

#banner-section p {
    /* margin-bottom: 40px; */
    margin-bottom: 30px;
    font-size: 1.4rem;
    font-weight: bold;
}

#banner-section .orange-btn{
    padding: 10px 30px !important;
    font-size: 1.5rem;
}


/** Custom **/
#banner-section h1, #banner-section p {
    text-shadow: 2px 2px 10px #393939;
}
/***/

.floating-img-1 {
    text-align: center;
    margin-left: 15%;
    margin-top: -7%;
}

.floating-img-2 {
    position: absolute;
    right: 5%;
    margin-top: -16%;
}

.floating-img-3 {
    position: absolute;
    margin-top: -12%;
    left: -3%;
}

#counter-section {
    background: #F9F8F7;
    padding: 3% 0 5%;
}

#counter-section h2 {
    margin-top: 50px;
    margin-bottom: 30px;
}

#counter-section p {
    text-align: left;
    margin-bottom: 30px;
}

#counter-section .num-counter {
    margin-top: 25px;
}

#counter-section .num-counter ul {
    list-style-type: none;
    display: inline-block;
    padding: 0;
}

#counter-section .num-counter ul li {
    display: inline;
    background: #EFEEEC;
    border-radius: 4px;
    padding: 17px 16px;
    font-weight: 700;
    font-size: 46px;
    margin: 10px 1px;
}

#nochebuena-section {
    background: #006660 url(../img/dark-green-curved-bg.svg) no-repeat top center;
    background-size: contain;
    color: #fff;
    padding: 3% 0;
}

#nochebuena-section h2 {
    text-align: center;
    margin-bottom: 30px;
}

#nochebuena-section .cards-container {
    padding: 40px 15%;
}

#nochebuena-section .cards-container .info-card {
    background: #fff;
    border: #EEEEEE solid 1px;
    text-align: center;
    border-radius: 17px;
    padding: 25px 30px;
    height: 225px;
}

#nochebuena-section .cards-container .info-card .figures {
    color: #46BB95;
}

#nochebuena-section .cards-container .info-card .figures .num {
    font-size: 40px;
    font-weight: 700;
}

#nochebuena-section .cards-container .info-card .figures .label {
    font-size: 26px;
    font-weight: 700;
    margin-left: 5px;
}

#nochebuena-section .cards-container .info-card p {
    font-size: 16px;
    color: #231F20;
    font-weight: 700;
}

#donation-btn-section {
    /* background: #006660 url(../img/xmas-light.svg) repeat-x bottom center; */
    padding: 2% 0;
    text-align: center;
    margin-top: -2px;
}

#donation-btn-section .orange-btn {
    margin-bottom: 15%;
    display: inline-block;
}

#donation-section {
    padding: 5% 0;
    text-align: center;
}

#donation-section h2 {
    font-weight: 400;
    margin-bottom: 30px;
    text-align: left;
}

#donation-section h2 .orange-text {
    color: #FF6A00;
    font-weight: 700;
}

#donation-section p {
    margin-bottom: 60px;
    text-align: left;
}

#donation-section img {
    width: 100%;
}

#image-section {
    background: url(../img/light-green-curved-bg.svg) no-repeat bottom center;
    background-size: cover;
    background-position-y: 85px;
    text-align: center;
}

#image-section img {
    width: 100%;
}

#image-section h2 {
    color: #fff;
    font-size: 42px;
    padding: 45px 0 20px;
}

#xmascard-section {
    /* background: #46BB95 url(../img/xmas-light-2.svg) repeat-x bottom center; */
    padding: 2% 0 20%;
    color: #fff;
    margin-top: -2px;
}

#xmascard-section h2 {
    font-size: 42px;
    margin-bottom: 35px;
}

#xmascard-section p {
    margin-bottom: 30px;
}

#xmascard-section .m-hide {
    margin: 0 auto 30px;
    display: block;
}

#footer-section {
    background: #221E20;
    color: #fff;
    text-align: center;
}

#footer-section .sm-icons {
    list-style-type: none;
    display: inline-block;
    margin: 30px 0;
    padding: 0;
}

#footer-section .sm-icons li {
    display: inline;
}

#footer-section .sm-icons li a {
    padding: 10px 15px;
}

#footer-section p {
    /* color: #CAC4BB; */
    color: #fff;
    font-size: 14px;
}

.d-hide {
    display: none;
}



/** Custom **/
.campaign-item {
    padding-top: 70px;
    padding-bottom: 70px;
    color: #fff;
}

.campaign-item .caption{
    min-height: 400px;
    display: flex;
    align-items:center;
}

.campaign-item img {
    border-radius: 10px !important;
}

/** Learn more **/
.lm-container {
    /* border-color: transparent; */
    border-radius: 0 !important;
}

.lm-container b {
    padding: 0;
}

.bg-green {
    background-color: #006661;
    color: #fff;
}

.bg-light-green {
    background-color: #46bb95;
    color: #fff;
}

.bg-blue{
    background-color: #00acca;
    color: #fff;
}

.bg-orange{
    background-color: #ff6b00;
    color: #fff;
}

.bg-purple{
    background-color: #9054a1;
    color: #fff;
}

.bg-maroon{
    background-color: #842e14;
    color: #fff;
}

.lm-container .card-header {
    border-radius: 0;
}

/********/

.banner-mobile{
    display: none !important;
}

/* ------- Mobile Menu ------- */

@media (max-width: 1024px) {
    .fixed {
        position: fixed !important;
        width: 100%;
        background: #fff;
        /* text-align: center; */
        z-index: 3;
    }

    #top-header {
        padding: 10px 0;
    }
    #top-header .mobile-menu {
        display: block;
        text-align: center;
    }
    #top-header .desktop-menu {
        display: none;
    }
    #top-header .mobile-menu .menu-icon {
        position: absolute;
        left: 20px;
        top: 18px;
    }
    #side-menu {
        position: fixed;
        top: 0;
        background: #fff;
        padding: 20px;
        height: 100%;
        z-index: 9999;
        width: 300px;
    }
    #side-menu .menu-close {
        float: right;
        margin-bottom: 20px;
    }
    #side-menu .menu-close img {
        width: 27px;
    }
    #side-menu .brand-logo {
        display: block;
        margin: 0 auto 30px;
    }
    #side-menu span {
        font-size: 14px;
        color: #999999;
    }
    #side-menu .top-menu {
        list-style: none;
        padding: 0;
        margin-bottom: 40px;
    } 
    #side-menu .top-menu li a {
        font-size: 20px;
        margin-bottom: 7px;
        color: #000000;
        text-decoration: none;
        display: inline-block;
    }
    #side-menu .orange-btn {
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        display: inline-block;
        text-align: center;
    }
}

/* ------- Media Queries ------- */

@media (max-width: 1440px) {
    .floating-img-1 {
        margin-left: 7%;
        margin-top: -10%;
    }
    .floating-img-2 {
        right: 3%;
    }
    .floating-img-3 {
        margin-top: -16%;
    }
}

@media (max-width: 1366px) {
    #top-header .top-menu {
        margin-left: 20px;
    }
    #top-header .top-menu li a {
        font-size: 19px;
    }
    
    #banner-section p, #counter-section p, #nochebuena-section p, #donation-section p, #xmascard-section p {
        font-size: 24px;
    }
    #counter-section h2, #nochebuena-section h2, #donation-section h2 {
        font-size: 34px;
    }
    #counter-section h3 {
        font-size: 24px;
    }
    #nochebuena-section .cards-container .info-card .figures .label {
        font-size: 23px;
    }
    #nochebuena-section .cards-container .info-card p {
        font-size: 14px;
    }
    #nochebuena-section .cards-container .info-card {
        height: 215px;
    }
    .floating-img-1 {
        margin-top: -13%;
    }
    
    /** cUSTOM **/
    #banner-section h1 {
        font-size: 42px;
        font-size: 5rem !important; 
    }
    #banner-section p {
        font-size: 1rem !important;
    }
    #banner-section img {
        width: 260px;
    }
}

@media (max-width: 1024px) {
    body.noscroll{
        overflow: hidden;
    }
    .floating-img-2 img, .floating-img-3 img {
        width: 245px;    
    }
    #counter-section {
        padding: 8% 0 15%;
    }
    #nochebuena-section .cards-container .info-card {
        height: 228px;
    }
    #counter-section h2 {
        text-align: left;
    }
    
    /** cUSTOM **/
    #banner-section h1 {
        font-size: 4.8rem !important; 
    }
    #banner-section p {
        font-size: 1rem !important;
    }
    #banner-section img {
        width: 250px;
    }

    .campaign-item .order-1{
        order: 2 !important;
    }

    .campaign-item .order-2{
        order: 1 !important;
        margin-bottom: 20px;
    }
}

@media (max-width: 991px) {
    #banner-section {
        /* background: #F9F8F7 url(../img/levelup-banner-mobile.jpg) no-repeat bottom center; */
        background-size: cover;
    }

    #banner-section .banner-txt {
        width: 100%;
        order: 2 !important;
        margin-bottom: 35%;
        text-align: center;
    }
    #banner-section .banner-txt h1, #banner-section .banner-txt p {
        text-align: left;
    }
    #banner-section .banner-img {
        width: 100%;
        order: 1 !important;
    }
    #banner-section .fimg1 {
        float: left;
        clear: right;
    }
    #banner-section .fimg2 {
        float: right;
        clear: left;
        margin-top: -150px;
    }
    .m-hide {
        display: none !important;
    }
    .d-hide {
        display: block !important;
    }
    .floating-img-2 {
        right: 6%;
        margin-top: -25%;
    }
    #nochebuena-section {
        padding: 15% 0 3%;
    }
    #nochebuena-section h2 {
        text-align: left;
    }
    #nochebuena-section .cards-container {
        background: #fff;
        border: #EEEEEE solid 1px;
        padding: 0;
        width: 350px;
        margin: 20px auto 40px;
        border-radius: 17px;
    }
    #nochebuena-section .cards-container .info-card {
        background: transparent;
        border: none;
        height: auto;
    }
    #donation-section {
        padding: 8% 0 10%;
    }
    #donation-section h2 {
        margin-top: 30px;
    }
    #xmascard-section .orange-btn {
        margin-top: 20px;
        display: inline-block;
    }
    #xmascard-section .d-hide {
        margin: 0 auto 30px;
    }

    /** Custom **/
    .campaign-item .caption{
        min-height: 390px;
        align-items: start;
    }

    .banner-desktop{
        display: none !important;
    }

    .banner-mobile{
        display: block !important;
    }
}

@media (max-width: 768px) {
    .floating-img-2 {
        margin-top: -36%;
    }
    #counter-section {
        padding: 8% 0 20%;
    }
    .floating-img-3 {
        margin-top: -20%;
    }
    #nochebuena-section {
        padding: 22% 0 3%;
    }
}

@media (max-width: 767px) {
    .floating-img-2 {
        margin-top: -30%;
    }
}

@media (max-width: 680px) {
    #counter-section {
        padding: 15% 0 20%;
    }
    #nochebuena-section {
        padding: 30% 0 3%;
    }
}

@media (max-width: 575px) {
    .container {
        width: 90%;
    }
    #banner-section .fimg1 {
        width: 330px;
    }
    #banner-section .fimg2 {
        width: 200px;
        margin-top: -90px;
    }
    #banner-section h1 {
        margin-top: 30px;
    }
    #counter-section {
        padding: 30% 0 40%;
    }
    .floating-img-3 {
        margin-top: -38%;
    }
    #nochebuena-section {
        padding: 22% 0 3%;
    }
    #donation-btn-section .orange-btn {
        margin-bottom: 40%;
    }
    #xmascard-section {
        padding: 2% 0 40%;
    }
    

    /** Custom **/
    .slick-prev {
        left: 0px !important;
    }

    .slick-next {
        right: 5px !important;
    }
}

@media (max-width: 480px) {
    #top-header .mobile-menu .brand-logo {
        width: 180px;
    }
    #top-header .mobile-menu .menu-icon {
        left: 15px;
        top: 13px;
    }
    #banner-section .fimg1 {
        width: 300px;
        margin-top: -13px;
    }
    #banner-section .fimg2 {
        width: 183px;
        margin-top: -46px;
    }
    .floating-img-2 {
        margin-top: -48%;
    }
    #banner-section {
        padding-bottom: 100px;
    }
    #banner-section p {
        margin-bottom: 55px;
    }
    #counter-section {
        padding-top: 122px;
        padding-bottom: 200px;
    }
    #nochebuena-section {
        padding-top: 140px;
    }
    #nochebuena-section .cards-container {
        width: 100%;
    }
    #banner-section h1 {
        font-size: 38px;
    }
    #counter-section h2, #nochebuena-section h2, #donation-section h2, #image-section h2, #xmascard-section h2 {
        font-size: 36px;
    }
    #banner-section p, #counter-section p, #nochebuena-section p, #donation-section p, #xmascard-section p {
        font-size: 18px;
    }
    #nochebuena-section .cards-container .info-card p {
        font-size: 16px;
    }
    #nochebuena-section .cards-container .info-card {
        padding: 40px 30px;
    }
    .orange-btn {
        padding: 25px;
    }
    #donation-section {
        padding-bottom: 80px;
    }
    #footer-section p {
        font-size: 12px;
    }
}

@media (max-width: 449px) {
    #counter-section .num-counter ul li {
        padding: 17px 15px;
        font-size: 44px;
    }
}

@media (max-width: 427px) {
    #counter-section .num-counter ul li {
        font-size: 34px;
    }
}

@media (max-width: 425px) {
    #banner-section p, #donation-section p {
        margin-bottom: 30px;
    }
    .orange-btn {
        display: inline-block;
        width: 100%;
    }
    #xmascard-section .orange-btn {
        margin-top: 0;
    }
    #image-section h2 {
        padding-top: 28px;
    }
}

@media (max-width: 375px) {
    #banner-section .fimg1 {
        width: 225px;
    }
    #banner-section .fimg2 {
        width: 146px;
    }
    #banner-section h1 {
        font-size: 30px;
        margin-top: 15px;
    }
    #counter-section h2, #nochebuena-section h2, #donation-section h2, #image-section h2, #xmascard-section h2 {
        font-size: 30px;
    }
    #banner-section p, #counter-section p, #nochebuena-section p, #donation-section p, #xmascard-section p {
        font-size: 16px;
    }
    #counter-section {
        padding-top: 145px;
        padding-bottom: 170px;
    }
    #counter-section .num-counter ul li {
        padding: 17px 13px;
    }
    #nochebuena-section {
        padding-top: 165px;
    }
    #xmascard-section .d-hide {
        width: 100%;
    }
    #nochebuena-section .cards-container .info-card {
        padding: 25px 30px;
    }
    #donation-btn-section .orange-btn, #xmascard-section .orange-btn {
        font-size: 17px;
    }
    #footer-section .sm-icons li a {
        padding: 10px 5px;
    }
    #footer-section p {
        font-size: 10px;
    }
}

@media (max-width: 360px) {
    #counter-section .num-counter ul li {
        padding: 17px 12px;
    }
}

@media (max-width: 320px) {
    #top-header .mobile-menu .brand-logo {
        margin-left: 25px;
    }
    #counter-section .num-counter ul li {
        padding: 17px 9px;
    }
    .orange-btn {
        font-size: 16px;
    }
    .floating-img-2 {
        margin-top: -60%;
    }
    #nochebuena-section {
        padding-top: 186px;
    }
    #donation-btn-section .orange-btn, #xmascard-section .orange-btn {
        font-size: 14px;
    }
    #banner-section h1, #counter-section h2, #nochebuena-section h2, #donation-section h2, #image-section h2, #xmascard-section h2 {
        font-size: 26px;
    }
}


/**** FAQ **/

#top-header {
    background: #fff;
}

.faq-page {
    background: #f9f8f7;
}

#faq-banner-section {
    /* background: #46BB95 url(../img/levelup-banner.jpg) repeat-x top center; */
    background-size: 1200px;
    background-position-y: -15px;
    height: 200px;
}

#grey-curve {
    background: #46BB95;
    margin-top: -2px;
}

#grey-curve img {
    width: 100%;
    margin-bottom: -2px;
}

#faq-content-section {
    padding-bottom: 10%;
}

#faq-content-section h1 {
    text-align: center;
    font-size: 38px;
    color: #221E20;
    margin-bottom: 40px;
}

#faq-content-section h2 {
    font-size: 1.8rem;
    color: #FF6A00;
    font-weight: bold;
    margin-bottom: 30px;
}

#faq-content-section p {
    font-size: 24px;
    color: #323232;
    line-height: 1.3;
    margin-bottom: 25px;
}

#faq-content-section li {
    font-size: 24px;
    color: #323232;
    line-height: 1.3;
}

#faq-content-section a {
    color: #FF6A00;
}

#faq-content-section a:hover {
    color: #ff8936;
}

#faq-content-section hr {
    border-color: #707070;
    margin: 45px 0;
}

@media (max-width: 425px) {
    #faq-banner-section {
        /* background: #46BB95 url(../img/levelup-banner-mobile.jpg) repeat-x top center; */
        background-size: 800px;
        background-position-y: -15px;
        height: 150px;
    }
    .faq-box {
        padding: 0 10px;
    }
    #faq-content-section h2 {
        font-size: 24px;
    }
    #faq-content-section p, #faq-content-section li {
        font-size: 18px;
    }
}

/**/
.carousel-control-prev-icon, .carousel-control-next-icon
{
    height: 3rem;
    width: 3rem;
}

.invoice-container {
    padding-top: 50px;
    padding-bottom: 50px;
}

.invoice {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    position: relative;
  }